// --------------------
// Import Bootstrap
// --------------------

// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";
@import "abstracts/variables";
@import "abstracts/mixins";

//// Optional
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
//@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
//@import "~bootstrap/scss/badge";
//@import "~bootstrap/scss/jumbotron";
//@import "~bootstrap/scss/alert";
//@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
//@import "~bootstrap/scss/toasts";
//@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
//@import "~bootstrap/scss/carousel";
//@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

@import "~aos/src/sass/aos";

.vhm-100 {
  min-height: 100vh;
}

.vh-100 {
  height: 100vh;
}

// --------------------
// Styles
// --------------------

* {
  word-wrap: break-word;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

.page {
  min-height: 100%;
}

#content {
  overflow: auto;
}

p {
  text-align: justify;
}

// Animation
.navbar {
  -webkit-transition: all 1s ease-out;
  -moz-transition: all 1s ease-out;
  -o-transition: all 1s ease-out;
  transition: all 1s ease-out;
}

.section-header {
  padding: map_get($spacers, 3);
}

.highlight {
  padding-bottom: map_get($spacers, 3);
  border-bottom: 2px solid $primary;
}

// --------------------
// Animations
// --------------------

$animation-duration: 3s;
$animation-elements: 4;

.animated-words {
  display: inline-block;
  position: relative;
}

.animated-words-element:nth-child(2) {
  animation-delay: $animation-duration;
}

.animated-words-element:nth-child(3) {
  animation-delay: $animation-duration * 2;
}

.animated-words-element:nth-child(4) {
  animation-delay: $animation-duration * 3;
}

//.animated-words-element:nth-child(5) {
//  animation-delay: 12s;
//}

.animated-words-element {
  animation: animatedWords $animation-duration * 4 linear infinite 0s;
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
}

/*topToBottom Animation*/
@keyframes animatedWords {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    transform: translateY(0px);
  }
  25% {
    opacity: 1;
    transform: translateY(0px);
  }
  30% {
    opacity: 0;
    transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 575.98px) {
  //Manage Display Headers for Mobile Devices
  .display-1 {
    font-size: 3rem;
    font-weight: 300;
    line-height: 1.0;
  }

  .display-2 {
    font-size: 2.75rem;
    font-weight: 300;
    line-height: 1.0;
  }

  .display-3 {
    font-size: 2.25rem;
    font-weight: 300;
    line-height: 1.0;
  }

  .display-4 {
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1.0;
  }

  h1, .h1 {
    font-size: 2rem;
  }

  h2, .h2 {
    font-size: 1.75rem;
  }
}

// icons
a.link-hero {
  color: $white;
  text-align: center;

  > div {
    transform: scale(1);
    transition: .5s transform ease-in-out;
  }

  .icon {
    background-color: transparent;
    transition: .5s background-color ease-in-out;
    position: relative;
    overflow: auto;

    &:before, &:after {
      background-color: transparent;
      transition: .5s opacity ease-in-out;
      opacity: 0;

      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      z-index: -1;
    }
  }

  .number {
    font-size: $h3-font-size;
  }

  &:hover {
    color: $white;

    > div {
      transform: scale(1.1);
    }

    .icon {
      &:before, &:after {
        opacity: .8;
      }

      &:before {
        background-color: $primary;

      }

      &:after {
        background: linear-gradient(to left top, transparentize($darker, 0.5), transparentize($darker, 0.2));
      }
    }
  }

  &.small {

    .number {
      font-size: $h3-font-size * 0.8;
    }

    .icon {
      transform: scale(0.8);
    }
  }
}

.bg-gradient {
  position: relative;

  &:before, &:after {
    background-color: transparent;
    transition: .5s opacity ease-in-out;

    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    z-index: -1;
  }

  &:before {
    background-color: $primary;

  }

  &:after {
    background: linear-gradient(to left top, transparentize($darker, 0.5), transparentize($darker, 0.2));
  }
}