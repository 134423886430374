@import "../../assets/styles/abstracts/variables";
@import "../../assets/styles/abstracts/mixins";
@import "~bootstrap/scss/mixins";

.sub-navbar-wrapper {

}

#header {
  position: relative;

  z-index: 0;

  &:before, &:after {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: -1;

    @include transition(.3s filter ease-in-out);
  }

  &:before {
    background-image: url("../../assets/img/home_header_bg.jpg");

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include animation(0s, 3s, blurDown);
  }

  &:after {
    background-color: rgba($darker, 0.6);
  }
}

@include keyframe(blurDown) {
  0% {
    filter: blur(3px);
  }

  100% {
    filter: blur(.1px);
  }
}
