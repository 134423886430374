@import "../../assets/styles/abstracts/variables";
@import "../../assets/styles/abstracts/mixins";

#navbar {
}

#menuButton, #menuOpenButton {
  .icon {
    @include animation(0s, .5s, showElement);
  }
}

#smallMenu {
  overflow-y: auto;
  display: none;

  position: fixed;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  z-index: 1050;

  background-color: transparentize($darker, 0.1);

  &.active {
    display: block;

    @include animation(0s, .5s, showElement);
  }
}

@include keyframe(showElement) {
  0% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
