@import "../../assets/styles/abstracts/variables";
@import "~bootstrap/scss/mixins/transition";

#services {
  .visible-scroll {
    position: relative;
    @include transition($transition-base);

    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;

    .number {
      opacity: 0;
      @include transition($transition-fade);
    }

    &:before, &:after {
      background-color: transparent;
      opacity: 0;

      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      z-index: -1;

      @include transition($transition-base);
    }

    &.active {
      //color: $white;

      background-color: transparentize($primary, .9);
      border-top: 1px solid transparentize($primary, .5);
      border-bottom: 1px solid transparentize($primary, .5);

      .number {
        opacity: 1;
      }

      &:before, &:after {
        opacity: 1;
      }

      &:before {
        background-color: transparent;
      }

      &:after {
      //background: linear-gradient(to left top, transparentize($darker, 0.9), transparentize($darker, 0.95));
      }

      .icon {
        &:before, &:after {
          //opacity: 0;
        }
      }
    }
  }
}