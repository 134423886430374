@import "../../assets/styles/abstracts/variables";
@import "../../assets/styles/abstracts/mixins";
@import "~bootstrap/scss/mixins";

.btn.button-hero {
  color: $white;
  background-color: rgba($primary, 0.5);
  border-width: 0;
  padding-top: map_get($spacers, 3);
  padding-bottom: map_get($spacers, 3);

  @include transition(.3s background-color ease-in-out);

  &:hover {
    background-color: rgba($primary, 1);
    color: $white;
    text-decoration: none;
  }
}