@import "../../assets/styles/abstracts/variables";

#contact {
  position: relative;

  background-image: url("../../assets/img/footer_bg.jpg");

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  z-index: 0;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba($dark, 0.6);
    background: linear-gradient(to bottom right, rgba($dark, 0.6), rgba($primary, 0.2));

    z-index: -1;
  }

  .form-control {
    background-color: rgba($darker, 0.6);
  }

  .contact-item {
    border-right: 1px solid rgba($secondary, .2);

    &:last-child {
      border-right: none;
    }
  }
}