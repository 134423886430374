@import "../../assets/styles/abstracts/variables";

#footer {
  .list-group {
    flex-direction: row;

    .list-group-item {
      flex-grow: 1;
      flex-basis: 0;

      border: none;
      //border-right: $border-width solid $dark;

      //&:last-child{
      //  border-right: none;
      //}
    }
  }
}