@import "../../assets/styles/abstracts/variables";
@import "~bootstrap/scss/mixins";

#sub-navbar {
  z-index: 1000;

  a {
    @include transition(.3s all ease-in-out);

    &:hover, &:focus {
      background-color: $secondary;
      color: $white;

      text-decoration: none;
    }

    &.active{
      background-color: darken($secondary, 10%);
      color: $white;

      text-decoration: none;
    }
  }
}