@import "../../assets/styles/abstracts/variables";

#services {
  .icon {
    position: relative;
    overflow: auto;
    color: $white;

    &:before, &:after {
      background-color: transparent;

      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      z-index: -1;
    }

    &:before {
      background-color: $primary;
    }

    &:after {
      background: linear-gradient(to left top, transparentize($darker, 0.5), transparentize($darker, 0.2));
    }
  }
}